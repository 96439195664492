// Generated by Framer (018ad06)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { withTap } from "../codeFile/FramerOverrides.com.tsx";
const MotionAWithTap = withTap(motion.a);

const cycleOrder = ["dnmbRm2BH"];

const serializationHash = "framer-pHukZ"

const variantClassNames = {dnmbRm2BH: "framer-v-nt9e1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, jwMLBrOb_: link ?? props.jwMLBrOb_} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jwMLBrOb_, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dnmbRm2BH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={jwMLBrOb_}><MotionAWithTap {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-nt9e1", className, classNames)} framer-4zmzys`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"dnmbRm2BH"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fit", pixelHeight: 356, pixelWidth: 1094, positionX: "center", positionY: "center", sizes: "160px", src: "https://framerusercontent.com/images/0vrSG4w2FY6VqAHp0xtcp0l4ns.png", srcSet: "https://framerusercontent.com/images/0vrSG4w2FY6VqAHp0xtcp0l4ns.png?scale-down-to=512 512w,https://framerusercontent.com/images/0vrSG4w2FY6VqAHp0xtcp0l4ns.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/0vrSG4w2FY6VqAHp0xtcp0l4ns.png 1094w"}} className={"framer-1qlvhj4"} layoutDependency={layoutDependency} layoutId={"lR_n9sRNW"}/></MotionAWithTap></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pHukZ.framer-4zmzys, .framer-pHukZ .framer-4zmzys { display: block; }", ".framer-pHukZ.framer-nt9e1 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-pHukZ .framer-1qlvhj4 { flex: none; height: 48px; position: relative; width: 160px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pHukZ.framer-nt9e1 { gap: 0px; } .framer-pHukZ.framer-nt9e1 > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-pHukZ.framer-nt9e1 > :first-child { margin-left: 0px; } .framer-pHukZ.framer-nt9e1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"jwMLBrOb_":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framermbl_Hxe5m: React.ComponentType<Props> = withCSS(Component, css, "framer-pHukZ") as typeof Component;
export default Framermbl_Hxe5m;

Framermbl_Hxe5m.displayName = "Navigation / Logo";

Framermbl_Hxe5m.defaultProps = {height: 48, width: 160};

addPropertyControls(Framermbl_Hxe5m, {jwMLBrOb_: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framermbl_Hxe5m, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})